import React, { useState } from "react";
import { connect } from "react-redux";
import { Card, Row, Col, Container, Input } from "reactstrap";
import Arrow_DownWords from "../../../../../../../../../../../../src/designUtils/Logo/Arrow_DownWords.svg";
import Vector_Arrow from "../../../../../../../../../../../../src/designUtils/Logo/Vector_Arrow.svg";

function AssetsProductList(props) {
  const [selectedParent, setSelectedParent] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [searchInput, setSearchInput] = useState(""); 

  const groupedChildren = props.childProducts.reduce((acc, child) => {
    const parentId = child.parentProduct;
    if (!acc[parentId]) {
      acc[parentId] = [];
    }
    acc[parentId].push(child);
    return acc;
  }, {});

  const handleParentClick = (parentAsin) => {
    setIsActive(parentAsin);
    setSelectedParent((prevSelected) =>
      prevSelected === parentAsin ? null : parentAsin
    );
  };

  const filterParentProductsWithChildren = () => {
    return props.parentProducts.filter((parent) => {
      const parentMatches =
        parent.asin.toLowerCase().includes(searchInput.toLowerCase()) ||
        parent.productTitle.toLowerCase().includes(searchInput.toLowerCase());

      const childrenMatch =
        groupedChildren[parent.asin]?groupedChildren[parent.asin].some(
          (child) =>
            child.asin.toLowerCase().includes(searchInput.toLowerCase()) ||
            child.productTitle.toLowerCase().includes(searchInput.toLowerCase())):'' || false;

      return parentMatches || childrenMatch;
    });
  };

  const filterOrphanProducts = () => {
    return props.orphanProducts.filter(
      (orphan) =>
        orphan.asin.toLowerCase().includes(searchInput.toLowerCase()) ||
        orphan.productTitle
          .toLowerCase()
          .includes(searchInput.toLowerCase())
    );
  };

  const filteredParentProducts = filterParentProductsWithChildren();
  const filteredOrphanProducts = filterOrphanProducts();

  return (
    <Container>
      <Row>
        <Col md={12} className="mt-2 mb-3 d-flex justify-content-end">
          <Input
            type="text"
            className='w-25'
            placeholder="Search by ASIN or Product Title"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
        </Col>

        {filteredParentProducts.map((parent) => (
          <Col md={12} key={parent.id}>
            <Card
              className={`mb-3 ${
                parent.asin === isActive ? "selected-assets-parent-card" : ""
              }`}
              onClick={() => handleParentClick(parent.asin)}
              style={{ cursor: "pointer" }}
            >
              <Row className="p-3">
                <Col md={1}>
                  <img
                    src={parent.itemImage}
                    height={80}
                    width={80}
                    alt="Parent Product"
                  />
                </Col>
                <Col md={10}>
                  <p className="mb-0">
                    <b>{parent.productTitle}</b>
                  </p>
                  <p className="mb-0">ASIN: {parent.asin}</p>
                </Col>
                <Col md={1}>
                <div className="justify-content-end d-flex  ">
                {parent.asin === isActive ? (
                    <img src={Arrow_DownWords} />
                  ) : (
                    <img src={Vector_Arrow} />
                  )}
                </div>
                  
                </Col>
              </Row>

              {selectedParent === parent.asin &&
                groupedChildren[parent.asin] &&
                groupedChildren[parent.asin]
                  .filter(
                    (child) =>
                      child.asin
                        .toLowerCase()
                        .includes(searchInput.toLowerCase()) ||
                      child.productTitle
                        .toLowerCase()
                        .includes(searchInput.toLowerCase())
                  )
                  .map((child) => (
                    <Card
                      key={child.id}
                      className="child-card mx-3 my-2 p-2"
                      onClick={() => props.setProductDetails(child)}
                    >
                      <Row>
                        <Col md={1}>
                          <img
                            src={child.itemImage}
                            height={60}
                            width={60}
                            alt="Child Product"
                          />
                        </Col>
                        <Col md={11}>
                          <p className="mb-0">
                            <b>{child.productTitle}</b>
                          </p>
                          <p className="mb-0">ASIN: {child.asin}</p>
                        </Col>
                      </Row>
                    </Card>
                  ))}
            </Card>
          </Col>
        ))}

        {filteredOrphanProducts.map((orphan) => (
          <Col md={12} key={orphan.id}>
            <Card
              className="mb-3"
              onClick={() => props.setProductDetails(orphan)}
            >
              <Row className="p-3">
                <Col md={1}>
                  <img
                    src={orphan.itemImage}
                    height={80}
                    width={80}
                    alt="Orphan Product"
                  />
                </Col>
                <Col md={11}>
                  <p className="mb-0">
                    <b>{orphan.productTitle}</b>
                  </p>
                  <p className="mb-0">ASIN: {orphan.asin}</p>
                </Col>
              </Row>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.loggedUser.token,
    accessLevel: state.loggedUser.accessLevel,
    status: state.loggedUser.status,
    storeOverview: state.accountOverview.store.storeOverview,
    childProducts: state.accountOverview.store.childProducts,

    adProfiles: state.accountOverview.landing.adProfiles,
    parentProducts: state.accountOverview.store.parentProducts,
    orphanProducts: state.accountOverview.store.orphanProducts,
  };
};
export default connect(mapStateToProps)(AssetsProductList);
