import React, { useEffect, useState } from "react";
import GetCatalogueProductDetailsService from "../../../../../../../../../../../Service/DashboardServices/AccountOverviewServices/StoreServices/GetCatalogueProductDetailsService";
import { connect } from "react-redux";
import { Row, Col, Card } from "reactstrap";

function ProductCatalogueDetailsFile(props) {
  const [catalogProductDetails, setCatalogProductDetails] = useState(null); // Hold the product details
  const [isLoading, setIsLoading] = useState(false); // Handle loading state

  useEffect(() => {
    const fetchCatalogueDetails = async () => {
      setIsLoading(true); // Start loading
      const response = await GetCatalogueProductDetailsService(
        props.token,
        props.ASIN,
        props.integerID
      );
      if (response) {
        console.log("Fetched Catalogue Product Details:", response);
        setCatalogProductDetails(response); // Save response in state
      }
      setIsLoading(false); // Stop loading
    };

    fetchCatalogueDetails();
  }, [props.token, props.ASIN, props.integerID]);

  return (
    <Row>
      <Col md={12} className="">
        {isLoading ? (
          <p>Loading product details...</p> // Loading indicator
        ) : catalogProductDetails ? (
          // Render the CatalogueItemDetails directly
          <Card className='catlogProductDetails-card p-3'>
            <Row>
              <Col md={12} className="d-flex ">
                <div>
                  <table id="catalogue-item">
                    <tbody>
                      <tr>
                        <td className="font-18 width-250 font-weight-bold">
                          Release Date
                        </td>
                        <td className="font-18">
                          : {catalogProductDetails.ReleaseDate}
                        </td>
                      </tr>
                      <tr>
                        <td className="font-18 width-250 font-weight-bold">
                          Marketplace Id
                        </td>
                        <td className="font-18 width-350">
                          : {catalogProductDetails.MarketplaceId}
                        </td>
                      </tr>
                      <tr>
                        <td className="font-18 width-250 font-weight-bold">
                          Publisher
                        </td>
                        <td className="font-18">
                          : {catalogProductDetails.Publisher}
                        </td>
                      </tr>
                      <tr>
                        <td className="font-18 width-250 font-weight-bold">
                          Manufacturer
                        </td>
                        <td className="font-18 ">
                          : {catalogProductDetails.Manufacturer}
                        </td>
                      </tr>
                      <tr>
                        <td className="font-18 width-250 font-weight-bold">
                          Studio
                        </td>
                        <td className="font-18">
                          : {catalogProductDetails.Studio}
                        </td>
                      </tr>
                      <tr>
                        <td className="font-18 width-250 font-weight-bold">
                          Binding
                        </td>
                        <td className="font-18 ">
                          : {catalogProductDetails.Binding}
                        </td>
                      </tr>
                      <tr>
                        <td className="font-18 width-250 font-weight-bold">
                          Brand
                        </td>
                        <td className="font-18">
                          : {catalogProductDetails.Brand}
                        </td>
                      </tr>
                      <tr>
                        <td className="font-18 width-250 font-weight-bold">
                          Color
                        </td>
                        <td className="font-18">
                          : {catalogProductDetails.Color}
                        </td>
                      </tr>
                      <tr>
                        <td className="font-18 width-250 font-weight-bold">
                          Flavor
                        </td>
                        <td className="font-18 ">
                          : {catalogProductDetails.Flavor}
                        </td>
                      </tr>
                      <tr>
                        <td className="font-18 width-250 font-weight-bold">
                          Label
                        </td>
                        <td className="font-18">
                          : {catalogProductDetails.Label}
                        </td>
                      </tr>
                      <tr>
                        <td className="font-18 width-250 font-weight-bold">
                          List Price
                        </td>
                        <td className="font-18">
                          :{" "}
                          {catalogProductDetails.ListPrice
                            ? `${catalogProductDetails.ListPrice.Amount} ${catalogProductDetails.ListPrice.CurrencyCode}`
                            : ""}
                        </td>
                      </tr>
                      <tr>
                        <td className="font-18 width-250 font-weight-bold">
                          Model
                        </td>
                        <td className="font-18">
                          : {catalogProductDetails.Model}
                        </td>
                      </tr>
                      {/* Add remaining rows here */}
                    </tbody>
                  </table>
                  <p className="font-18 mb-0 mt-2">
                    <b>Sales Rankings :</b>
                  </p>
                  {catalogProductDetails.SalesRankings
                    ? catalogProductDetails.SalesRankings.map((ranking) => (
                        <p className="font-18 mb-0">
                          <span>#</span>
                          {ranking.Rank} <span>in</span>{" "}
                          {ranking.ProductCategoryId},{" "}
                        </p>
                      ))
                    : ""}
                </div>
              </Col>
            </Row>
          </Card>
        ) : (
          <p>No product details available.</p> // Fallback message
        )}
      </Col>
    </Row>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.loggedUser.token,
    accessLevel: state.loggedUser.accessLevel,
    storeOverview: state.accountOverview.store.storeOverview,
  };
};

export default connect(mapStateToProps)(ProductCatalogueDetailsFile);
