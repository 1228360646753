import ReactTable from "react-table";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  FormFeedback,
  FormGroup,
  Input,
  CardTitle,
  Alert,
  Table,
} from "reactstrap";
import CustomModal from "../../../../../../Components/Modals/CustomModal";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardCheck,faFilePdf,faFileExcel,faFileWord,faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons/faWindowClose";
import AmazonTicketRaised from "../../../../../../../designUtils/Storeoverview/status/AmazonTicketRaised.svg"
import AmazonResponseReceived from "../../../../../../../designUtils/Storeoverview/status/AmazonResponseReceived.svg"
import OPSSWAddNewCommentAttachmentService from "../../../../../../../Service/OPSServices/OPSTicketServices/OPSSWAddNewCommentAttachmentService";
import Assigned from "../../../../../../../designUtils/Storeoverview/status/Assigned.svg";
import Closed from "../../../../../../../designUtils/Storeoverview/status/Closed.svg";
import NeedInfo from "../../../../../../../designUtils/Storeoverview/status/NeedInfo.svg";
import Open from "../../../../../../../designUtils/Storeoverview/status/Open.svg";
import Resolved from "../../../../../../../designUtils/Storeoverview/status/Resolved.svg";
import Updated from "../../../../../../../designUtils/Storeoverview/status/Updated.svg";
import InProgress from "../../../../../../../designUtils/Storeoverview/status/InProgress.svg";
import Pending from "../../../../../../../designUtils/Storeoverview/status/Pending.svg";
import { faEye, faDownload } from "@fortawesome/free-solid-svg-icons";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Editor, SetInnerHtmlWithClassReplacement } from "../../../../../../../Utils/Constants";
import OPSSWGetCommentAttachmentService from "../../../../../../../Service/OPSServices/OPSTicketServices/OPSSWGetCommentAttachmentService";
import OPSGetAllProductsAndMembers from "../../../../../../../Service/OPSServices/OPSTicketServices/OPSGetAllProductsAndMembers";
import CalculateSWTeamMemberTicketPerformanceService from "../../../../../../../Service/OPSServices/SWInternalServices/CalculateSWTeamMemberTicketPerformanceService";
function OpsDashSWViewTicket(props) {
  const [ticketHistory, setTicketHistory] = useState([]);
  const [ticketHistoryComments, setTicketHistoryComments] = useState();
  const [productList, setProductList] = useState([]);
  const [isAttachmentOpen, setIsAttachmentOpen] = useState(false);
  const [filePath, setFilePath] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [text, setText] = useState("");
  const [ticketHistoryText, setTicketHistoryText] = useState("");
  const [ticketHistoryUpdatedBy, setTicketHistoryUpdatedBy] = useState("");
  const [isTicketHistoryTextOpen, setIsTicketHistoryTextOpen] = useState(false);
  const [assignedTo, setAssignedTo] = useState("");
  const [priority, setPriority] = useState("");
  const [isExpandedHistory, setIsExpandedHistory] = useState(false)
  const [openAmazonDetails, setOpenAmazonDetails] = useState(false);
  const [accessLevel, setAccessLevel] = useState();
  const [loggedInUser, setLoggedInUser] = useState({ userId: '', accepted: false })
  const [accept, setAccept] = useState(false)
  const [allFiles,setAllFiles]=useState([])
  const [ filePoup,setFilePoup] =useState(false)
  const [ popupFilePath, setPopupFilePath]=useState('')
  const [amazonButtonDisable, setAmazonButtonDisable]= useState(false)
  const [latestAttachmets,setLatestAttachmets]=useState([])
  const [maxLengthComments,setMaxLengthComments]=useState()
  const [status, setStatus] = useState({
    ticketStatus: "",
  });
  const [searchText, setSearchText] = useState("");
  const [state, setState] = useState({
    ticketTitle: "",
    ticketRequestType: "",
    ticketDate: "",
    ticketStatus: "",
    issueDescription: "",
    priority: "",
    assignedTo: "",
    assignedToID: "",
    ticketSubRequestType: "",
    Asin: "",
    Sku: "",
    problemAreaId: "",
    problemAreaTitle: "",
    problemAreaPathwayId: "",
    problemAreaPathwayTitle: "",
    isInternalTicket: "",
    amazonCaseId: "",
    amazonCaseUrl: "",
    sellerCentralAccessId: "",
  });
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [amazonCaseId, setAmazonCaseId] = useState(props.amazonCaseId ? props.amazonCaseId : '');
  const [amazonCaseUrl, setAmazonCaseUrl] = useState((props.amazonCaseUrl != '#' && props.amazonCaseUrl != '') ? props.amazonCaseUrl : "");
  const [tempAmazonCaseId, setTempAmazonCaseId] = useState(props.amazonCaseId ? props.amazonCaseId : '');
  const [tempAmazonCaseUrl, setTempAmazonCaseUrl] = useState((props.amazonCaseUrl != '#' && props.amazonCaseUrl != '') ? props.amazonCaseUrl : "");
  const [errors, setErrors] = useState({ tempAmazonCaseUrl: "" });
  const [isValidUrl, setIsValidUrl] = useState(false);
  const [numericIdMsg, setNumericIdMsg] = useState(false);
  const [onChangeFlag,setOnChangeFlag] = useState(false);
  const [errors1, setErrors1] = useState(false)
  const handleRemoveFile = (file) => {
    const updatedFiles = selectedFiles.filter((f) => f !== file);
    setSelectedFiles(updatedFiles);
  };
   
  const [isExpanded, setIsExpanded] = useState(false);
  const [warningpopupOpen, setwarningpopupOpen] = useState(false);
  const [yesButton, setyesButton] = useState(false);
  const [temp, setTemp] = useState(0);
const handleYesAction = () => {
    console.log("Performing Yes action...");
    setyesButton(true)
    if(temp === 1){
      saveData(tempAmazonCaseId, tempAmazonCaseUrl, 1)
    }
    if(temp === 2){
      handleUpload('Amazon Response Received','','',true)
    }
    if(temp === 3){
      handleUpload(1,'','',true);
    }
    if(temp === 4){
      handleUpload(3,'','',true);
    }
    setwarningpopupOpen(false)
};

const handleNoAction = () => {
  if(temp === 3){
    handleUpload(1,'','',false);
  }
  if(temp === 1){
    setOpenAmazonDetails(false)
  }
  // if(temp === 3){
  //   handleUpload(3,'','',false);
  // }
  console.log("Performing No action...");
  setwarningpopupOpen(false)

};



  const setTicketInfo = () => {
    if (props.ticketList && props.ticketId) {
      var requestMainType = "";
      props.ticketList.map((ticket) => {
        {
          if (ticket.ticketId === props.ticketId) {
            props.mainRequestTypes.map((Type) => {
              {
                if (Type.id === ticket.mainRequestTypeId) {
                  let asinArray = [];
                  let problemAreaPathwayTitle = "";
                  if (props.problemAreaPathwayList) {
                    props.problemAreaPathwayList.map((pap) => {
                      if (props.problemAreaPathwayId == pap.id) {
                        problemAreaPathwayTitle = pap.problemAreaPathway;
                      }
                    });
                  }
                  setState({
                    ticketTitle: ticket.ticketTitle,
                    ticketDate: ticket.ticketCreatedAt,
                    ticketStatus: ticket.ticketStatus,
                    issueDescription: ticket.issueDescription,
                    priority: ticket.priority,
                    assignedTo: ticket.assignedTo,
                    assignedToID: ticket.assignedToID,
                    ticketRequestType: Type.requestType,
                    ticketSubRequestType: ticket.requestSubTypes,
                    //Asin: asinArray,//ticket.Asin,
                    Sku: ticket.Sku,
                    problemAreaPathwayTitle: ticket.problemAreaPathway,
                    isInternalTicket: ticket.isInternalTicket,
                    amazonCaseId: ticket.amazonCaseId,
                    amazonCaseUrl: ticket.amazonCaseUrl,
                    sellerCentralAccessId: ticket.sellerCentralAccessId,
                  });
                }
              }
            });
          }
        }
      });
    }
  };
  const handleFileChange = (e) => {
    const newFiles = [...selectedFiles];
    for (let i = 0; i < e.target.files.length; i++) {
      newFiles.push(e.target.files[i]);
    }
    setSelectedFiles(newFiles);
  };

  const handleStatusChange = (e) => {
    const { name, value } = e.target;
    setStatus({ ...status, [name]: value });
  };
  const handleAssignedTo = (e) => {
    // setOnChangeFlag(true)
    const { name, value } = e.target;
    setAssignedTo(value);
  };
  const handlePriorityChange = (e) => {
    // setOnChangeFlag(false)
    const { name, value } = e.target;
    console.log(value);
    setPriority(value);
  };
  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };
  useEffect(() => {
    const data = {
      ticketId: props.ticketId,
    };
    OPSSWGetCommentAttachmentService(props.token, data).then((response) => {
      if (response) {
        console.log(response);
        setAccessLevel(response.ticketHistory[0].accessLevel)
        setLoggedInUser(response.loggedInUser)
        setTicketHistoryComments(response.ticketHistory[0].ticketHistoryComment);
        setLatestAttachmets(response.ticketHistory[0].ticketAttachment)
        if (ticketHistory) {

          setTicketHistory(response.ticketHistory);
        }
        if (response.productList) {
          setProductList(response.productList);
        }
      }
    });
  }, []);
  useEffect(() => {
    setTicketInfo();
  }, []);
  const handleAssignedToCheck = (event) => {
    const newAcceptValue = event.target.checked;
    setAccept(newAcceptValue)
    let data = {
      ticketId: props.ticketId,
      accept: newAcceptValue,
      loggedInUser: loggedInUser.userId,
      ticketStatus: 'Accepted'
    }
    if (newAcceptValue == true) {
      CalculateSWTeamMemberTicketPerformanceService(props.token, data).then((res) => {
        if (res) {
          console.log(res)
        }
      })
    }

  }
  const filteredTicketHistory = ticketHistory.filter((row) => {
    const historyComment = row.ticketHistoryComment.toLowerCase();
    return historyComment.includes(searchText.toLowerCase());
  });

  const handleUpload = async (flag, id, url,action) => {
    console.log(yesButton)
    if (state.ticketStatus === "Closed") {
      return true;
    }
    if (state.assignedToID == loggedInUser.userId && loggedInUser.accepted == false && state.ticketStatus !== "Closed" && accept == false) {
      setErrors1('Please accept ticket first then continue.')
      return;
    }
    const formData = new FormData();
    if (state.isInternalTicket && flag == 3) {
      formData.append("ticketStatus", "Closed");
      setLoading1(true);
    } else if (flag == 'Amazon Ticket Raised') {
      // formData.append("ticketHistoryComment",'Amazon Ticket has been raised for this tickets.')
      formData.append("ticketHistoryComment", '<p>Amazon Case has been raised for this ticket.</p> <p> &nbsp; Amazon Case Id : ' + id + ' </p> <p> &nbsp; URL : ' + url + ' </p>')
      formData.append("ticketStatus", 'Amazon Ticket Raised');
      setLoading(true);
    }
    else if (flag == 'Amazon Response Received') {


      formData.append("ticketStatus", 'Amazon Response Received');
      setLoading2(true);
    }
    else if (props.accessLevel == 0 && state.ticketStatus !== "Resolved") {
      formData.append("ticketStatus", state.ticketStatus);
      setLoading(true);
    }
    if (flag != 3 && flag != 'Amazon Response Received') {
      setLoading(true);
    }
    else if (flag == 'Amazon Response Received') {
      setLoading2(true);
    }
    // else{
    //   formData.append("ticketStatus", 0);
    // }
    if (flag == 'Amazon Ticket Raised') {
      formData.append("amazonCaseId", id);
      formData.append("amazonCaseUrl", url);
    }
    else {
      formData.append("amazonCaseId", amazonCaseId);
      formData.append("amazonCaseUrl", amazonCaseUrl);
    }
    if (flag != 'Amazon Ticket Raised') {
      if (state.ticketStatus === "Resolved" && flag !== 'Amazon Response Received') {
        formData.append("ticketStatus", 'Ticket Updated');
        console.log("if (flag != 'Amazon Ticket Raised') {");
        formData.append("ticketHistoryComment", text?text:'Ticket has been reopened by Simpliworks.');
      }
      else{
        console.log("else (flag != 'Amazon Ticket Raised') {");
        // formData.append("ticketStatus", 'Ticket Updated');
        formData.append("ticketHistoryComment", text);
      }
    }
    formData.append("flag", flag);
    formData.append("priority", action ? (priority ? priority : state.priority) : state.priority);
    formData.append("assignedTo", assignedTo ? assignedTo : state.assignedToID);
    formData.append("ticketId", props.ticketId);
    formData.append("storeName", props.storeNameForViewTicket);
    formData.append("integerId", props.storeIdForViewTicket);
    formData.append('action',action)
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append("ticketAttachmentFile", selectedFiles[i]);
    }
    // console.log(formData)
    // console.log(JSON.stringify(formData))
    // return true
    OPSSWAddNewCommentAttachmentService(props.token, formData).then(
      (response) => {
        if (response) {
          console.log(response);
          if (flag == 2) {
            setOpenAmazonDetails(false)
            setAmazonCaseId(response.amazonCaseId)
            setAmazonCaseUrl(response.amazonCaseUrl)
            setTempAmazonCaseId(response.amazonCaseId)
            setTempAmazonCaseUrl(response.amazonCaseUrl)
            props.handleAmazonCasePopup(response.allTicketList);
          }
          else {
            setText("");
            setSelectedFiles(null);
            if (props.isFromCreatingTicket == false) {
              props.handleCreateAndViewTicketClose(response.allTicketList);
            }
            else {
              props.handleCreateTicketPopup(response);
            }
          }
          setLoading(false);
          setLoading1(false);
          setLoading2(false);
          setOnChangeFlag(false);
          setAmazonButtonDisable(false)

        }
      }
    );
  };

const isImage = (fileName) => {
  const imageExtensions = ["jpg", "jpeg", "png", "gif"];
  const extension = fileName.split(".").pop().toLowerCase();
  return imageExtensions.includes(extension);
};
const isPdfFile =(fileName)=>{
  const imageExtensions = ["pdf"];
  const extension = fileName.split(".").pop().toLowerCase();
  return imageExtensions.includes(extension);
}
const isExcelFile =(fileName)=>{
  const imageExtensions = ["xlsx","xls","csv"];
  const extension = fileName.split(".").pop().toLowerCase();
  return imageExtensions.includes(extension);
}
  const show = (path) => {
    
    const separatedPath = path.split("/");
    console.log("first");
    console.log(separatedPath[1]);
    const ext = separatedPath[1].split(".")[1];
    console.log(ext);
    if (ext == "xlsx" || ext == "csv" || ext == "xls" || ext == "docx") {
      setFilePath(path);
      setIsAttachmentOpen(true);
      setTimeout(() => {
        setIsAttachmentOpen(false);
      }, 200);
    } else {
      setFilePath(path);
      setIsAttachmentOpen(true);
    }
  };
    const downloadFile = async (file) => {
    console.log(file);
    if (true) {
      try {
        let fileName = file.ticketAttachment.split("/")[1];
        fileName = fileName.split(".")[0];
        const response = await fetch(
          "https://simpliworks-project-onboarding-dev.s3.amazonaws.com/images/" +
          file.ticketAttachment
        );
        if (!response.ok)
          throw new Error(
            `Network response was not ok for ${file.ticketAttachmentId}`
          );

        const blob = await response.blob();
        const blobUrl = URL.createObjectURL(blob);

        const downloadLink = document.createElement("a");
        downloadLink.href = blobUrl;
        downloadLink.download = fileName || "downloaded_file";
        // downloadLink.download = 'downloaded_file';

        document.body.appendChild(downloadLink);
        downloadLink.click();

        document.body.removeChild(downloadLink);
        URL.revokeObjectURL(blobUrl);
      } catch (error) {
        console.error("Failed to download files:", error);
      }
    }
  };
  const viewSingleHistory = (text, updatedBy) => {
    setTicketHistoryText(text);
    setTicketHistoryUpdatedBy(updatedBy);
    setIsTicketHistoryTextOpen(true);
  };
  const stripHtmlTags = (html) => {
    if (!html) return '';
    return html.replace(/<[^>]*>/g, '');
  };
  const fileExtensions = ["csv", "docx", "xlsx", "xls"];
  const columns = [
    // {
    //   Header: "Priority",
    //   accessor: "ticketPriority",
    //   Cell: (cellInfo) => (
    //     <div className="d-flex justify-content-center w-100">
    //       <p className="m-0">{cellInfo.value}</p>
    //     </div>
    //   ),
    // },
    // {
    //   Header: "Communication",
    //   accessor: "Path",
    //   Cell: (cellInfo) => (
    //     <div className="d-flex justify-content-center w-100">
    //       <p className="m-0">{cellInfo.value}</p>
    //     </div>
    //   ),
    // },
    {
      Header: "Status",
      width:80,
      accessor: "ticketHistoryStatus",
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <span
            className={
              cellInfo.value === "Closed"
                ? "btn btn-sm text-primary "
                : cellInfo.value === "New"
                  ? "btn btn-sm text-danger"
                  : cellInfo.value === "Resolved"
                    ? "btn btn-sm text-success"
                    : cellInfo.value === "Need info"
                      ? "btn btn-sm text-info "
                      : cellInfo.value === "Informed"
                        ? "btn btn-sm text-secondary "
                        : cellInfo.value === "Ticket updated"
                          ? "btn btn-sm text-warning"
                          : cellInfo.value === "Assigned"
                            ? "btn btn-sm text-warning"
                            : "btn btn-sm"
            }
          >
            {cellInfo.value}
          </span>
        </div>
      ),
    },
    {
      Header: "Comments",
      width:220,
      accessor: "ticketHistoryComment",
      Cell: (cellInfo) => (
        <div className="d-flex  w-100">
          <p
            className="m-0 comment-paragraph"
            style={{ color: "#3D85C6", cursor: "pointer" }}
            onClick={() =>
              // viewSingleHistory(
              //   cellInfo.value,
              //   cellInfo.original.ticketHistoryCreateBy
              // )
              {setAllFiles(cellInfo.original.ticketAttachment);
                viewSingleHistory(
                  cellInfo.value,
                  cellInfo.original.ticketHistoryCreateBy)}
            }
          >
           
            {stripHtmlTags(cellInfo.value)}
          </p>
          {cellInfo.original.ticketAttachment.length > 0?<p className="mt-1" style={{fontSize:'15px'}}> 
             <FontAwesomeIcon
                className="mr-2"
                icon={faPaperclip}
                onClick={() => {setAllFiles(cellInfo.original.ticketAttachment);
                  viewSingleHistory(
                    cellInfo.value,
                    cellInfo.original.ticketHistoryCreateBy)}}
            /></p>:''}
        </div>
      ),
    },
    {
      Header: "Updated by",
      width:100,
      accessor: "ticketHistoryCreateBy",
      Cell: (cellInfo) => (
        <div className="d-flex  w-100">
          <p className="m-0">{cellInfo.value}</p>
        </div>
      ),
    },
    {
      Header: "Date",
      width:120,
      accessor: "ticketHistoryCreatedAtOriginal",
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <p className="m-0">{cellInfo.original.ticketHistoryCreatedAt}</p>
        </div>
      ),
    },


    // {
    //   Header: "Assigned",
    //   accessor: "assignedTouserName",
    //   Cell: (cellInfo) => (
    //     <div className="d-flex justify-content-center w-100">
    //       <p className="m-0">{cellInfo.value}</p>
    //     </div>
    //   ),
    // },

  ];

  const validateUrl = (url) => {
    const urlPattern = /^(https?:\/\/)/i;
    return urlPattern.test(url);
  };
  const saveData = (tempAmazonCaseId, tempAmazonCaseUrl, flag) => {
    setAmazonButtonDisable(true)
    let ticketStatus = ''
    if (flag == 1) {
      ticketStatus = 'Amazon Ticket Raised'
    }
    if (flag == 2) {
      ticketStatus = 'Amazon Response Received'
    }
    setAmazonCaseId(tempAmazonCaseId)
    setAmazonCaseUrl(tempAmazonCaseUrl)
    handleUpload(ticketStatus, tempAmazonCaseId, tempAmazonCaseUrl)
  }
  const handleTextChange = (e) => {
    // setText(e.target.value);
    const { name, value } = e.target;
    let newErrors = { ...errors };
    if (name === "tempAmazonCaseUrl") {
      const isValid = validateUrl(value);
      setIsValidUrl(isValid);
      if (!isValid && value.length > 0) {
        newErrors[name] =
          "Please enter a valid URL starting with http:// or https://";
      } else {
        newErrors[name] = "";
      }
      // setAmazonCaseUrl(value);
      setTempAmazonCaseUrl(value);
    } else if (name === "tempAmazonCaseId") {
      if (/^\d*$/.test(value)) {
        // setAmazonCaseId(value);
        setTempAmazonCaseId(value);
        newErrors[name] = "";
        setNumericIdMsg(false);
      } else {
        return setNumericIdMsg("Please enter only numbers");
      }
    } else {
      setText(e.target.value);
    }
    setErrors(newErrors);
  };
  const handleQuillChange = (value) => {
    setOnChangeFlag(true)
    setText(value);
  };
  const replaceIssueDescriptionText = SetInnerHtmlWithClassReplacement(state.issueDescription);
  const replaceTicketHistoryComments = SetInnerHtmlWithClassReplacement(ticketHistoryComments?ticketHistoryComments:'');
  const longText = replaceIssueDescriptionText;
  const replaceticketHistoryText = SetInnerHtmlWithClassReplacement(ticketHistoryText);

  const ReadMore = ({ htmlContent, maxLength, id }) => {

    const toggleReadMore = () => {
      setIsExpanded(!isExpanded);
    };

    const truncatedContent = htmlContent.length > maxLength ? htmlContent.substring(0, maxLength) + '...' : htmlContent;
    return (
      <div>
        <div className="issue-description-set d-flex flex-wrap" dangerouslySetInnerHTML={{ __html: truncatedContent }} />
        {htmlContent.length > maxLength && (
          <span style={{ fontSize: '13px' }} onClick={toggleReadMore} className="cursor-pointer d-flex justify-content-end">
            {isExpanded ? ' Read Less' : '....Read More'}
          </span>
        )}
      </div>
    )
  };
  const handleOpenAmazonDetails = () => {
    if(loggedInUser.userId === '')
    {
      return true
    }
    if(state.ticketStatus === "Closed"){
      return true
    }
    if (state.assignedToID == loggedInUser.userId && loggedInUser.accepted == false && state.ticketStatus !== "Closed" && accept == false) {
      setErrors1('Please accept ticket first then continue.')
      return;
    }
    else{
      setOpenAmazonDetails(true)
    }
    
  }

  const ReadMorelatestComments = ({ htmlContent, maxLength }) => {

    const toggleReadMore = () => {
      setIsExpandedHistory(!isExpandedHistory)
    };
    setMaxLengthComments(htmlContent.length)
    const truncatedContent = htmlContent.length > maxLength ? htmlContent.substring(0, maxLength) + '...' : htmlContent;
    return (
      <div className="">
        <div className="issue-description-set d-flex flex-wrap" dangerouslySetInnerHTML={{ __html: truncatedContent }} />
        {htmlContent.length > maxLength && (
          <span style={{ fontSize: '13px' }} onClick={toggleReadMore} className="cursor-pointer d-flex justify-content-end">
            ....Read More
          </span>
        )}
      </div>
    )
  };
  function stripHtml(htmlString) {
    return htmlString.replace(/<\/?[^>]+(>|$)/g, "");
  }
  const showImg=(file)=>{
    console.log(file)
    setFilePoup(true)
    // setFilePath(file)
    setPopupFilePath(file)
  }
  const viewDownloadFile = async (file) => {
    console.log("File info:", file);
    try {
      // Extract file name from the URL
      const fileUrl = file.ticketAttachment;
      const fileName = fileUrl.split('/').pop() || 'downloaded_file'; // Default file name
  
      // Fetch the file
      const response = await fetch(fileUrl);
  
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
  
      // Check the Content-Type to ensure it's not an HTML error page
      const contentType = response.headers.get('Content-Type');
      if (contentType && contentType.includes('html')) {
        throw new Error('Received an HTML response instead of the file.');
      }
  
      // Convert response to a blob
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);
  
      // Create a temporary download link
      const downloadLink = document.createElement('a');
      downloadLink.href = blobUrl;
      downloadLink.download = fileName;
      document.body.appendChild(downloadLink);
  
      // Trigger the download
      downloadLink.click();
  
      // Cleanup
      document.body.removeChild(downloadLink);
      URL.revokeObjectURL(blobUrl);
  
      console.log(`File downloaded successfully: ${fileName}`);
    } catch (error) {
      console.error('Failed to download file:', error);
    }
  };
  
  return (
    <Container fluid className="">
      <Card className="p-3 bgColor">
        <Row
          className="m-0 justify-content-center" style={{ fontSize: "19px" }}>
          <Col md={6}>
            <Row>
              <Col md={12}>
                <Label htmlFor="subRequestType">
                  <b>Products{productList ? `(${productList.length}) :` : ''}</b>
                </Label>
                <div
                  className={
                    productList
                      ? productList.length > 1
                        ? "scrollbar "
                        : " "
                      : ""
                  }
                >
                  {productList ? (
                    productList.length > 0 ? (
                      productList.map((product) => (
                        <>
                          <Row className="border row-background-color justify-content-center p-0 m-0">
                            {/* <Col md="2" className="p-2">
                              <div>
                                <img
                                  className="ml-0"
                                  width={"100%"}
                                  src={product.itemImage}
                                />
                              </div>
                            </Col> */}
                            <Col md={12} className="p-2">
                              <div>
                                <h6>
                                  <b>
                                    {product.asin} :
                                  </b> &nbsp;
                                  <a
                                    href={product.link}
                                    className="text-decoration-none "
                                    target="blank"
                                  >
                                    {product.productTitle}
                                  </a>
                                </h6>
                                <div className="">
                                  {/* <Row>
                                    <Col md={5}>
                                      <span>
                                        <b>ASIN : </b> 
                                      </span>
                                    </Col>
                                    <Col md={7}>
                                      {/* <div>
                                                      <DropdownWithCheckbox options={product.skus} onSelect={(values) => handleSelect(values, product.asin)} />
                                                    </div> 
                                    </Col>
                                  </Row> */}
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </>
                      ))
                    ) : (
                      <p className="text-center"> No product selected .</p>
                    )
                  ) : (
                    <p className="text-center"> No product selected .</p>
                  )}
                </div>
              </Col>
              <Col md={12}>
                <Label><b>Creater Priority : </b>{props.createrPriority?props.createrPriority:''}</Label>
                </Col>
              <Col md={12} >
                <Label for="Created Date">
                  <b>Issue Description : </b>
                </Label>
                <div className='custom-issue-dis-box'>
                  <ReadMore htmlContent={replaceIssueDescriptionText} maxLength={200} />
                </div>

                {/* <p className="issue-discription-set" dangerouslySetInnerHTML={{ __html: replaceIssueDescriptionText }} /> */}
                {/* <p>{state.issueDescription}</p> */}
              </Col>
              <Col md={6} className='mt-3'>
                <label className="d-flex justify-content-between" for="assignedTo">
                  <b>Assigned to : </b>
                  {(state.assignedToID == loggedInUser.userId && loggedInUser.accepted == false && state.ticketStatus !== "Closed") ?
                    <>{accept == false ?
                      <p className="mb-0 accept-checkbox">

                        <input
                          type='checkbox' className="mr-2"
                          checked={accept}
                          // className='mt-2'
                          onChange={handleAssignedToCheck} />
                        Accept
                      </p>
                      : ' '}</> : ''
                  }
                </label>
                <p className="mb-0">
                  {props.accessLevel === 0 &&
                    state.ticketStatus !== "Closed" ? (
                    <select
                      id="assignedTo"
                      name="assignedTo"
                      onChange={handleAssignedTo}
                      value={assignedTo ? assignedTo : state.assignedToID}
                      className="form-control"
                    >
                      <option value="">--Select Member--</option>
                      {props.teamMembers
                        ? props.teamMembers.map((member) => (
                          // (state.problemAreaPathwayTitle == member.specialization)
                          //           ?
                          <option
                            value={member.employeeId}
                            title={`${member.note}`}
                          >
                            {member.employeeName}
                          </option>
                            // :''
                            /*:''*/
                           /*:''*/))
                        : ""}
                    </select>
                  ) : (
                    state.assignedTo
                  )}
                </p>
                <p className="mb-0"> {errors1 && (accept == false) && <p className="text-danger">{errors1}</p>}</p>

              </Col>
              <Col md={6} className='mt-3'>
                <Label for="priority">
                  <b> Internal Priority : </b>
                </Label>
                <p>

                  {props.accessLevel === 0 &&
                    state.ticketStatus !== "Closed" ? (
                    <select
                      id="priority"
                      name="priority"
                      onChange={handlePriorityChange}
                      value={priority ? priority : state.priority}
                      className="form-control"
                      disabled={state.priority === "Internal Ticket"}
                    >
                      <option value="">--Select Priority--</option>
                      <option value="Urgent">Urgent</option>
                      <option value="High">High</option>
                      <option value="Normal">Normal</option>
                      <option value="Low">Low</option>
                      <option value="Onboarding Task">Onboarding Task</option>
                      <option value="Internal Ticket">Internal Ticket</option>
                    </select>
                  ) : (
                    state.priority
                  )}
                </p>
              </Col>
              <Col md={12} >
                <Row className="m-0">
                  <Col md="12" className="mbg-3 font-size-lg p-0 mt-2">
                    <h5><b>SW Execution History</b></h5>

                    <div>
                      <input
                        type="text"
                        className="form-control w-50 mb-3"
                        placeholder="Search Ticket History"
                        value={searchText}
                        onChange={handleSearchChange}
                      />
                    </div>
                    <ReactTable
                      data={filteredTicketHistory}
                      columns={columns}
                      className="-fixed -highlight -striped"
                      getTheadProps={() => {
                        return {
                          style: {
                            overflowY: "hidden",
                            background: "#B1FA63",
                            color: "#243837",
                          },
                        };
                      }}
                      defaultPageSize={5}
                      noDataText={"No Data Found."}
                      SubComponent={(row) => (
                        <div className="p-1">
                          {console.log(row.original.ticketAttachment)}
                          {row.original.ticketAttachment.length > 0 ? (
                            <ReactTable
                              data={row.original.ticketAttachment}
                              columns={[
                                {
                                  Header: "Name",
                                  width:450,
                                  accessor: "ticketAttachment",
                                  Cell: (cellInfo) => (
                                    <div className="d-flex justify-content-center w-100">
                                      <p className="comment-paragraph-ticket-history" title={cellInfo.value.split("/")[1]}>
                                        {cellInfo.value.split("/")[1]}
                                      </p>
                                    </div>
                                  ),
                                },
                                {
                                  Header: "Action",
                                  width:80,
                                  accessor: "ticketAttachment",
                                  Cell: (cellInfo) => (
                                    <div className="d-flex justify-content-center w-100">
                                      {fileExtensions.includes(
                                        cellInfo.value.split(".")[1]
                                      ) ? (
                                        <FontAwesomeIcon
                                          className="ml-3"
                                          style={{ cursor: "pointer" }}
                                          icon={faDownload}
                                          onClick={() =>
                                            downloadFile(cellInfo.original)
                                          }
                                        />
                                      ) : (
                                        <>
                                          <FontAwesomeIcon
                                            className="ml-3"
                                            style={{ cursor: "pointer" }}
                                            icon={faEye}
                                            onClick={() => show(cellInfo.value)}
                                          />
                                          <FontAwesomeIcon
                                            className="ml-3"
                                            style={{ cursor: "pointer" }}
                                            icon={faDownload}
                                            onClick={() =>
                                              downloadFile(cellInfo.original)
                                            }
                                          />
                                        </>
                                      )}
                                    </div>
                                  ),
                                },
                              ]}
                              getTheadProps={() => {
                                return {
                                  style: {
                                    overflowY: "hidden",
                                    background: "#B1FA63",
                                    color: "#243837",
                                  },
                                };
                              }}
                              className="-striped -highlight"
                              defaultPageSize={row.original.ticketAttachment.length}
                              noDataText="No Attachments available"
                            />
                          ) : (
                            <p className="text center">No Attachments.</p>
                          )}
                        </div>
                      )}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <Row className='p-1'>
              <Col md={5} className='p-0  '>
                <Label className=" ml-3">
                  {state.isInternalTicket ? (
                    <span className="">
                      <FontAwesomeIcon
                        style={{ color: "#05837c" }}
                        icon={faClipboardCheck}
                      />
                      &nbsp;<b>Internal Ticket</b>
                    </span>
                  ) : (
                    ""
                  )}

                </Label>
                <Label className="cursor-pointer mb-0 ml-3"
                  onClick={handleOpenAmazonDetails}>
                  <b>Amazon Case Id : </b>
                  <span >{amazonCaseId ? <a href={amazonCaseUrl ? amazonCaseUrl : ''} target='_blank'>{amazonCaseId}</a> : ''}</span>
                  {/* <span>{props.amazonCaseId?<a href={props.amazonCaseUrl} target='_blank'>{ props.amazonCaseId}</a> :<a href={amazonCaseUrl} target='_blank'>{amazonCaseId}</a>}</span> */}
                </Label>

              </Col>
              <Col md={7} className='p-0'>
                <Label for="Status">
                  <Col className="img-class-button pb-0 pt-0 d-flex justify-content-end">
                    <p
                      className={
                        state.ticketStatus === "New"
                          ? "Open-button mb-0 pt-0"
                          : state.ticketStatus === "Amazon Ticket Raised" ? 'amazon-ticket-raised  pb-0 pt-0' :
                            state.ticketStatus === "Amazon Response Received" ? 'amazon-response-received  pb-0 pt-0' :
                              state.ticketStatus === "Closed"
                                ? "closed-button  pb-0 pt-0"
                                : state.ticketStatus === "Resolved"
                                  ? "Resolved-button pb-0 pt-0"
                                  : state.ticketStatus === "Need info"
                                    ? "Needinfo-button pb-0 pt-0"
                                    : state.ticketStatus === "Informed"
                                      ? "Inprogress-button pb-0 pt-0"
                                      : state.ticketStatus === "Ticket Updated"
                                        ? "Ticketupdat-button pb-0 pt-0"
                                        : state.ticketStatus === "Assigned"
                                          ? "Assigned-button pb-0 pt-0"
                                          : state.ticketStatus === "Pending"
                                            ? "Pending-button pb-0 pt-0"
                                            : ""
                      }
                    >
                      {state.ticketStatus === "Closed" ? (
                        <img className="img-class" src={Closed} />

                      ) :
                        state.ticketStatus === 'Amazon Ticket Raised' ? <img className="img-class" src={AmazonTicketRaised} /> :
                          state.ticketStatus === 'Amazon Response Received' ? <img className="img-class" src={AmazonResponseReceived} /> :
                            state.ticketStatus === "New" ? (
                              <img className="img-class" src={Open} />
                            ) : state.ticketStatus === "Resolved" ? (
                              <img className="img-class" src={Resolved} />
                            ) : state.ticketStatus === "Informed" ? (
                              <img className="img-class" src={InProgress} />
                            ) : state.ticketStatus === "Ticket Updated" ? (
                              <img className="img-class" src={Updated} />
                            ) : state.ticketStatus === "Assigned" ? (
                              <img className="img-class" src={Assigned} />
                            ) : state.ticketStatus === "Pending" ? (
                              <img className="img-class" src={Pending} />
                            ) : state.ticketStatus === "Need info" ? (
                              <img className="img-class" src={NeedInfo} />
                            ) : (
                              ""
                            )}
                      <span
                        style={{ color: "white" }}
                        className="status-text"
                      >
                        {state.ticketStatus}
                      </span>
                    </p>
                  </Col>
                </Label>
              </Col>

              <Col md={12}>
                <div className="d-flex justify-content-between align-items-center">
                  <Label className=''> <b>SC Access Id :</b><span className="ml-3">
                    {/* <b>SP Access Id :</b> */}
                    {state.sellerCentralAccessId
                      ? state.sellerCentralAccessId
                      : "\u00A0-"}
                  </span> </Label>
                  <div className="d-flex justify-content-end">
                    {state.ticketStatus !== "Closed" && (
                      <>
                        {
                          amazonCaseId &&
                          <Button
                            className="btn_class ml-2"
                            size="lg"
                            title='Comments are mandatory while updating Amazon Response.'
                            onClick={() => {setTemp(2);((state.assignedToID != loggedInUser.userId) || state.assignedToID == null ? setwarningpopupOpen(true):setwarningpopupOpen(false));(state.assignedToID == loggedInUser.userId ? handleUpload('Amazon Response Received'):setyesButton(false));}}
                            disabled={!stripHtml(text) ? true : (state.ticketStatus === "Closed" || loading2)}
                          >
                            {loading2 ? "Updating..." : "Amazon Response Received"}
                          </Button>
                        }

                        <Button
                          className="btn_class ml-2"
                          size="lg"
                          onClick={() => {
                            // 1  for noting else 
                           (((state.assignedToID != loggedInUser.userId) || state.assignedToID == null) && assignedTo == '' ? setwarningpopupOpen(true):(state.assignedToID == loggedInUser.userId) ? setTemp(3) : handleUpload(1,'','',true));
                           (state.assignedToID == loggedInUser.userId ? setyesButton(true):setyesButton(false));
                           (state.assignedToID == loggedInUser.userId ? handleUpload(1,'','',true):setyesButton(false));
                           setTemp(3)
                            // 1 for save button action
                          }}
                          disabled={((state.ticketStatus === "Closed" || loading) || loggedInUser.userId =='')}//|| !onChangeFlag}
                        >
                          {loading ? "Saving..." : "Save"}
                        </Button>
                        {state.isInternalTicket && (
                          <Button
                            className="btn_class float-right ml-2"
                            size="lg"
                            // 3 for close internal ticket
                            onClick={() =>
                              //  handleUpload(3)
                              {
                                (((state.assignedToID != loggedInUser.userId) || state.assignedToID == null) && assignedTo == '' ? setwarningpopupOpen(true):(state.assignedToID == loggedInUser.userId) ? setTemp(4) : handleUpload(3,'','',true));
                                (state.assignedToID == loggedInUser.userId ? setyesButton(true):setyesButton(false));
                                (state.assignedToID == loggedInUser.userId ? handleUpload(3,'','',true):setyesButton(false));
                                setTemp(4)
                              }
                              }
                            disabled={(state.ticketStatus === "Closed" || loading1)  || loggedInUser.userId ==''}
                          >
                            {loading1 ? "Saving..." : "Mark Resolved"}
                          </Button>
                        )}
                      </>
                    )}

                  </div>
                </div>
                <p className="text-center mt-2">
                  {state.ticketStatus === "Closed" && <Alert>Note: This ticket is closed.</Alert>}
                </p>
              </Col>

              <Col md={12} className='mt-3'>
                {/* <div className="custom-issue-dis-box"> */}
                <Label><b>Latest Comment :</b></Label>
                <div className={accessLevel != 0 ? 'custom-issue-dis-box-user' : 'custom-issue-dis-box'}>

                  <p>
                    <ReadMorelatestComments htmlContent={replaceTicketHistoryComments} maxLength={200} />
                  </p>
                 
                {console.log(latestAttachmets)}{console.log('latestAttachmets',latestAttachmets.length)} 
                <div className="d-flex flex-wrap">
                  {latestAttachmets &&
                    latestAttachmets.map((file, index) => {
                      const fileUrl = `https://simpliworks-project-onboarding-dev.s3.amazonaws.com/images/${file.ticketAttachment}`;
                      const fileName = file.ticketAttachment;

                      if (isImage(fileName)) {
                        return (
                          <p
                            key={index}
                            style={{ cursor: "pointer" }}
                            onClick={() => showImg(fileUrl)}
                            className="d-flex"
                          >
                            <img
                              src={fileUrl}
                              alt="Attachment"
                              className="ml-2"
                              title="Image Attachment"
                              style={{ height: "50px" }}
                            />
                          </p>
                        );
                      } else if (isPdfFile(fileName)) {
                        return (
                          <p key={index} className="latest-comment-files">
                            <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                              <span  >
                                <FontAwesomeIcon
                                  style={{ color: "#05837c" }}
                                  icon={faFilePdf}
                                />
                                &nbsp;<span >{fileName.replace(/^static\//, "")}</span>
                              </span>
                            </a>
                          </p>
                        );
                      } else if (isExcelFile(fileName)) {
                        return (
                          <p key={index} className="latest-comment-files">
                            <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                              <span >
                                <FontAwesomeIcon
                                  style={{ color: "#05837c" }}
                                  icon={faFileExcel}
                                />
                                &nbsp;<span >{fileName.replace(/^static\//, "")}</span>
                              </span>
                            </a>
                          </p>
                        );
                      } else {
                        return (
                          <p key={index} className="latest-comment-files">
                            <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                              <span >
                                <FontAwesomeIcon
                                  style={{ color: "#05837c" }}
                                  icon={faFileWord}
                                />
                                &nbsp;<span >{fileName.replace(/^static\//, "")}</span>
                              </span>
                            </a>
                          </p>
                        );
                      }
                    })}
                  </div>
                </div>
              </Col>
              <Col md={12} className='mt-3'>
                <Label for="Comment">
                  <b>Comments For SW Team : </b>
                  {/* <span style={{ color: "red" }}>*</span> */}
                </Label>
                <ReactQuill
                  theme={'snow'}
                  modules={Editor.modules}
                  formats={Editor.formats}
                  value={text}
                  onChange={handleQuillChange}
                  bounds={'.quill-prod'}
                  placeholder='Please describe your problem' />
              </Col>
              <Col md={12} className='mt-3'>
                {state.ticketStatus !== "Closed" && (
                  <>
                    <Label for="Attachment">
                      <b>Helpful screenshots or images : </b>
                    </Label>
                    <Row>
                      <Col md={4}>
                      <label
                      htmlFor="fileInput"
                      className="custom-file-input-label text-center"
                    >
                      <i className="fas fa-upload"></i>
                      <br /> Choose File
                    </label>
                    <input
                      type="file"
                      id="fileInput"
                      name="ticketAttachment"
                      className="custom-file-input"
                      onChange={handleFileChange}
                    />
                      </Col>
                      <Col md={8}>
                      <span style={{ fontSize: "13px" }}><b>Attached File List</b></span>
                    <ul style={{ color: "green", fontSize: "13px" }}>
                      {selectedFiles
                        ? selectedFiles.map((file, index) => (
                          <li key={index}>
                            {file.name}
                            <button
                              className="btn"
                              onClick={() => handleRemoveFile(file)}
                            >
                              <FontAwesomeIcon
                                color="red"
                                size="15px"
                                icon={faWindowClose}
                              />
                            </button>
                          </li>
                        ))
                        : ""}
                    </ul>
                      
                      </Col>
                    </Row>
                  </>
                )}
              </Col>

            </Row>

          </Col>
        </Row>
      </Card>

      <CustomModal
        header={
          <>

            Attachment
            <FontAwesomeIcon
              className="ml-3"
              style={{ cursor: "pointer" }}
              icon={faDownload}
              onClick={() =>
                downloadFile({
                  ticketAttachment: filePath,
                  ticketAttachmentId: 0,
                })
              }
            />
          </>
        }
        isOpen={isAttachmentOpen}
        toggle={() => setIsAttachmentOpen(!isAttachmentOpen)}
        size={"lg"}
        className="curve-popup"
        footer={
          <>
            <Button
              color={"link"}
              onClick={() => setIsAttachmentOpen(!isAttachmentOpen)}
            >
              Cancel
            </Button>
          </>
        }
      >
        <Label>
          <div>
            <iframe
              src={
                "https://simpliworks-project-onboarding-dev.s3.amazonaws.com/images/" +
                filePath
              }
              title="File Display"
              width="100%"
              height="500"
            ></iframe>
          </div>
        </Label>
      </CustomModal>
      <CustomModal
        header={
          <>
            Attachment
            <FontAwesomeIcon
              className="ml-3"
              style={{ cursor: "pointer" }}
              icon={faDownload}
              onClick={() => viewDownloadFile({ ticketAttachment: popupFilePath,
                ticketAttachmentId: 0,})
                // downloadFile({
                  // ticketAttachment: filePath,
                  // ticketAttachmentId: 0,
                // })
              }
            />
          </>
        }
        isOpen={filePoup}
        toggle={() => setFilePoup(!filePoup)}
        size={"lg"}
        className="curve-popup"
        footer={
          <>
            <Button
              color={"link"}
              onClick={() => setFilePoup(!filePoup)}
            >
              Cancel
            </Button>
          </>
        }
      >
        <Label>
          <div>
            <iframe
              src={
                popupFilePath
              }
              title="File Display"
              width="100%"
              height="500"
            ></iframe>
          </div>
        </Label>
      </CustomModal>
      <CustomModal
        header={"Comment " + "By - " + ticketHistoryUpdatedBy}
        isOpen={isTicketHistoryTextOpen}
        toggle={() => setIsTicketHistoryTextOpen(!isTicketHistoryTextOpen)}
        size={"xl"}
        className="curve-popup"
        footer={
          <>
            <Button
              color={"link"}
              onClick={() =>
                setIsTicketHistoryTextOpen(!isTicketHistoryTextOpen)
              }
            >
              Cancel
            </Button>
          </>
        }
      >
        <p className="issue-discription-set" dangerouslySetInnerHTML={{ __html: replaceticketHistoryText }} />
        {/* <Label>{ticketHistoryText}</Label> */}
        <div className="d-flex flex-wrap">
      {allFiles && allFiles.map((file, index) => {
        const fileUrl = `https://simpliworks-project-onboarding-dev.s3.amazonaws.com/images/${file.ticketAttachment}`;
        const fileName = file.ticketAttachment;

        return isImage(fileName) ? (
          <p style={{cursor:'pointer'}} onClick={()=>showImg(fileUrl)}>
             <img
            key={index}
            src={fileUrl}
            alt="Attachment"
           className="ml-2"
            title="Image Attachment"
            style={{ height: "100px",  }}
          />
          </p>
         
        ) : (
          <p className="ml-2" key={index}>
            <a href={fileUrl} target="_blank" rel="noopener noreferrer">
            <ui><li>{file.ticketAttachment.replace(/^static\//, "")}</li></ui>
            </a>
          </p>
        );
      })}
    </div>
       
      </CustomModal>
      <CustomModal
        header={"Latest Comment"}
        isOpen={isExpandedHistory}
        toggle={() => setIsExpandedHistory(!isExpandedHistory)}
        size={"xl"}
        className="curve-popup"
        footer={
          <>
            <Button
              color={"link"}
              onClick={() =>
                setIsExpandedHistory(!isExpandedHistory)
              }
            >
              Cancel
            </Button>
          </>
        }
      >
        <p style={{ fontSize: "19px" }} className="issue-discription-set" dangerouslySetInnerHTML={{ __html: replaceTicketHistoryComments }} />
        {/* <Label>{ticketHistoryText}</Label> */}
        <div className="d-flex flex-wrap">
                  {latestAttachmets &&
                    latestAttachmets.map((file, index) => {
                      const fileUrl = `https://simpliworks-project-onboarding-dev.s3.amazonaws.com/images/${file.ticketAttachment}`;
                      const fileName = file.ticketAttachment;

                      if (isImage(fileName)) {
                        return (
                          <p
                            key={index}
                            style={{ cursor: "pointer" }}
                            onClick={() => showImg(fileUrl)}
                            className="d-flex"
                          >
                            <img
                              src={fileUrl}
                              alt="Attachment"
                              className="ml-2"
                              title="Image Attachment"
                              style={{ height: "50px" }}
                            />
                          </p>
                        );
                      } else if (isPdfFile(fileName)) {
                        return (
                          <p key={index} className="latest-comment-files ml-2">
                            <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                              <span  >
                                <FontAwesomeIcon
                                  style={{ color: "#05837c" }}
                                  icon={faFilePdf}
                                />
                                &nbsp;<span >{fileName.replace(/^static\//, "")}</span>
                              </span>
                            </a>
                          </p>
                        );
                      } else if (isExcelFile(fileName)) {
                        return (
                          <p key={index} className="latest-comment-files ml-2">
                            <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                              <span >
                                <FontAwesomeIcon
                                  style={{ color: "#05837c" }}
                                  icon={faFileExcel}
                                />
                                &nbsp;<span >{fileName.replace(/^static\//, "")}</span>
                              </span>
                            </a>
                          </p>
                        );
                      } else {
                        return (
                          <p key={index} className="latest-comment-files ml-2">
                            <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                              <span >
                                <FontAwesomeIcon
                                  style={{ color: "#05837c" }}
                                  icon={faFileWord}
                                />
                                &nbsp;<span >{fileName.replace(/^static\//, "")}</span>
                              </span>
                            </a>
                          </p>
                        );
                      }
                    })}
                  </div>
      </CustomModal>
      <CustomModal
        header={"Issue Description"}
        isOpen={isExpanded}
        toggle={() => setIsExpanded(!isExpanded)}
        size={"xl"}
        className="curve-popup"
        footer={
          <>
            <Button
              color={"link"}
              onClick={() =>
                setIsExpanded(!isExpanded)
              }
            >
              Cancel
            </Button>
          </>
        }
      >
        <p className="issue-discription-set" style={{ fontSize: '19px' }} dangerouslySetInnerHTML={{ __html: replaceIssueDescriptionText }} />
    
      </CustomModal>
      <CustomModal
        header={"Amazon Case Details"}
        isOpen={openAmazonDetails}
        toggle={() => { setOpenAmazonDetails(!openAmazonDetails); setTempAmazonCaseUrl(amazonCaseUrl); setTempAmazonCaseId(amazonCaseId) }}
        size={"md"}
        className="curve-popup"
        footer={
          <>
            <Button
              color={"link"}
              onClick={() => { setOpenAmazonDetails(!openAmazonDetails); setTempAmazonCaseUrl(amazonCaseUrl); setTempAmazonCaseId(amazonCaseId) }}
            >
              Cancel
            </Button>
          </>
        }
      >
        <Row style={{ fontSize: "19px" }}>
          {/* <Col md={5}>
            <Label className='ml-1'><b>Amazon CaseId :</b></Label></Col> */}
          <Col md={12} >
            <div className="d-flex justify-content-center">
              <input
                type="text"
                id="tempAmazonCaseId"
                name="tempAmazonCaseId"
                value={tempAmazonCaseId}
                // className="form-control mb-2"
                className={`form-control mb-2 w-75 ${tempAmazonCaseUrl && tempAmazonCaseId == ""
                  ? "is-invalid"
                  : ""
                  }`}

                placeholder="Amazon Case ID"
                onChange={handleTextChange}
              />
            </div>
            <p className="text-danger d-flex justify-content-center">{numericIdMsg}</p>
          </Col>
          {/* <Col md={5}>
            <Label className='ml-1'><b>Amazon CaseUrl :</b></Label>

          </Col> */}
          <Col md={12} >
            <div className="d-flex justify-content-center">
              <input
                type="text"
                id="tempAmazonCaseUrl"
                name="tempAmazonCaseUrl"
                value={tempAmazonCaseUrl}
                className={`form-control w-75 mb-2 ${tempAmazonCaseId && tempAmazonCaseUrl == ""
                  ? "is-invalid"
                  : ""
                  }`}
                placeholder="Amazon Case URL"
                onChange={handleTextChange}
              />

            </div>

            {errors.amazonCaseUrl && (
              <p className="text-danger d-flex justify-content-center">{errors.tempAmazonCaseUrl}</p>
            )}
             <p className="mb-0"> {errors1 && (accept == false) && <p className="text-danger text-center">{errors1}</p>}</p>
          </Col>
          <Col md={12} className="d-flex justify-content-center">
            <Button
              className="btn-background-color"
              disabled={(!tempAmazonCaseUrl || !tempAmazonCaseId || !!errors.tempAmazonCaseUrl) || loggedInUser.userId ==''|| amazonButtonDisable?true:false}
              onClick={() => {
                ((state.assignedToID != loggedInUser.userId) || state.assignedToID == null ? setwarningpopupOpen(true):setwarningpopupOpen(false));
                (state.assignedToID == loggedInUser.userId ? setyesButton(true):setyesButton(false));
                (state.assignedToID == loggedInUser.userId ? saveData(tempAmazonCaseId, tempAmazonCaseUrl, 1):setyesButton(false));
                setTemp(1)
                }}>
              Amazon Ticket Raised
            </Button>

          </Col>
        </Row>



        {/* <p className="issue-discription-set" dangerouslySetInnerHTML={{ __html: replaceIssueDescriptionText }} /> */}
        {/* <Label>{ticketHistoryText}</Label> */}
      </CustomModal>
      
      <CustomModal
                header={""}
                isOpen={warningpopupOpen}
                toggle={() => setwarningpopupOpen(!warningpopupOpen)}
                size={"md"}
                className="curve-popup"
             
            >
              <Card className="curve-popup p-3 ">
              <Row>
              <Col md={12}>
                <div className="modal-body">
                    <p style={{ fontSize: "18px" }}>Do you want to get the ticket assigned to yourself?</p>
                    {/* <p style={{ fontSize: "14px" }}>Note : You can only comment on the ticket without getting it assigned to yourself.</p> */}
                    <p style={{ fontSize: "14px" }}>NOTE: You are able to leave a comment without assigning the ticket to yourself.</p>
                </div>
                <div className='mb-2 d-flex justify-content-end mr-3'>
                                    <Button
                                       color="primary" onClick={() => handleYesAction()}
                                       >
                                        Yes
                                    </Button> &nbsp;
                                    <Button color="secondary" onClick={() => handleNoAction()} style={{ marginLeft: "10px" }}
                                        >
                                        No
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                        </Card>
            </CustomModal>
            <CustomModal
        header={
          <>
            Attachment
            <FontAwesomeIcon
              className="ml-3"
              style={{ cursor: "pointer" }}
              icon={faDownload}
              onClick={() => viewDownloadFile({ ticketAttachment: popupFilePath,
                ticketAttachmentId: 0,})
                // downloadFile({
                  // ticketAttachment: filePath,
                  // ticketAttachmentId: 0,
                // })
              }
            />
          </>
        }
        isOpen={filePoup}
        toggle={() => setFilePoup(!filePoup)}
        size={"lg"}
        className="curve-popup"
        footer={
          <>
            <Button
              color={"link"}
              onClick={() => setFilePoup(!filePoup)}
            >
              Cancel
            </Button>
          </>
        }
      >
        <Label>
          <div>
            <iframe
              src={
                popupFilePath
              }
              title="File Display"
              width="100%"
              height="500"
            ></iframe>
          </div>
        </Label>
      </CustomModal>
      
    </Container>
  );
}
const mapStateToProps = (state) => {
  return {
    token: state.loggedUser.token,
    accessLevel: state.loggedUser.accessLevel,
    status: state.loggedUser.status,
    error: state.loggedUser.error,
    newUser: state.loggedUser.newUser,
    storeOverview: state.accountOverview.store.storeOverview,
    storeProducts: state.accountOverview.store.storeProducts,
    needsAdvertisingAPIAuth: state.loggedUser.needsAdvertisingAPIAuth,
    user: state.LWA.user,
    isAuth: state.LWA.isAuth,
    enableMobileMenu: state.theme.enableMobileMenu,
    enableClosedSidebar: state.theme.enableClosedSidebar,
  };
};

export default connect(mapStateToProps)(OpsDashSWViewTicket);
