import React, { useEffect, useState } from 'react'
import { Container, Button, Col, Row, Card, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, Input, } from 'reactstrap'
import { connect } from 'react-redux';
import BackButton from "../../../../../.././../../../../designUtils/Logo/BackButton.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons/faWindowClose";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";
import uploadAssetsService from '../../../../../../../../../../Service/DashboardServices/AssetGalleryServices/uploadAssetsService';
import getAllOriginalFilesByAssetIdentifierService from '../../../../../../../../../../Service/DashboardServices/AssetGalleryServices/getAllOriginalFilesByAssetIdentifierService';
import ReactTable from 'react-table';
function UploadImagesAndOriginalFiles(props) {
  const [isCheckedImg, setIsCheckedImg] = useState(false);
  const [isCheckedAi, setIsCheckedAi] = useState(false);

  const [selectedImageFiles, setSelectedImageFiles] = useState([]);
  const [selectedGraphicFiles, setSelectedGraphicFiles] = useState([]);
  const [dragging, setDragging] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertMessageAi, setAlertMessageAi] = useState('');
  const [allOriginalFiles, setAllOriginalFiles] = useState([]);
  const [handleLinkFile, setHandleLinkFile] = useState(false);
  const [loadingButton,setLoadingButton]=useState(false)

  useEffect(() => {
    let data = {
      assetIdentifier: props.assetIdentifier? props.assetIdentifier:''
    }
    getAllOriginalFilesByAssetIdentifierService(props.token, data).then((response) => {
      if (response) {
        console.log(response)
        setAllOriginalFiles(response)
      }
    })
  }, [])
  const handleRemoveFile = (file) => {
    const updatedFiles = selectedImageFiles.filter((f) => f !== file);
    setSelectedImageFiles(updatedFiles);
  };
  const handleGraphicRemoveFile = (file) => {
    const updatedFiles = selectedGraphicFiles.filter((f) => f !== file);
    setSelectedGraphicFiles(updatedFiles);
  };
  const handleGraphicFileChange = (e) => {
    console.log(selectedGraphicFiles.length)
    const files = Array.from(e.target.files);

    const allowedExtensions = ['ai', 'psd', 'eps'];

    const filteredFiles = files.filter(file => {
      const extension = file.name.split('.').pop().toLowerCase();
      return allowedExtensions.includes(extension);
    });
    if (selectedGraphicFiles.length < 1) {
      setSelectedGraphicFiles(prevFiles => [...prevFiles, ...filteredFiles]);

      const invalidFiles = files.filter(file => !allowedExtensions.includes(file.name.split('.').pop().toLowerCase()));

      if (invalidFiles.length > 0) {
        setAlertMessageAi(`Invalid file(s) detected: ${invalidFiles.map(file => file.name).join(', ')}. Only ai, psd and eps  files are allowed.`);
      } else {
        setAlertMessageAi('');
      }
    }

    else {
      setAlertMessageAi('You can only select one file at a time. Please remove the existing file before selecting a new one.');
    }
  }

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const allowedExtensions = ['png', 'jpg', 'jpeg'];

    const filteredFiles = files.filter(file => {
      const extension = file.name.split('.').pop().toLowerCase();
      return allowedExtensions.includes(extension);
    });

    setSelectedImageFiles(prevFiles => [...prevFiles, ...filteredFiles]);

    const invalidFiles = files.filter(file => !allowedExtensions.includes(file.name.split('.').pop().toLowerCase()));

    if (invalidFiles.length > 0) {
      setAlertMessage(`Invalid file(s) detected: ${invalidFiles.map(file => file.name).join(', ')}. Only PNG, JPG, and JPEG files are allowed.`);
    } else {
      setAlertMessage('');
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);

    const files = Array.from(e.dataTransfer.files);
    const allowedExtensions = ['png', 'jpg', 'jpeg'];
    const filteredFiles = files.filter(file => {
      const extension = file.name.split('.').pop().toLowerCase();
      return allowedExtensions.includes(extension);
    });

    setSelectedImageFiles(prevFiles => [...prevFiles, ...filteredFiles]);

    const invalidFiles = files.filter(file => !allowedExtensions.includes(file.name.split('.').pop().toLowerCase()));

    if (invalidFiles.length > 0) {
      setAlertMessage(`Invalid file(s) detected: ${invalidFiles.map(file => file.name).join(', ')}. Only PNG, JPG, and JPEG files are allowed.`);
    } else {
      setAlertMessage('');
    }
  };
  const handleGADragEnter = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleGADragLeave = (e) => {
    e.preventDefault();
    setDragging(false);
  };

  const handleGADragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleGADrop = (e) => {
    e.preventDefault();
    setDragging(false);

    const files = Array.from(e.dataTransfer.files);
    const allowedExtensions = ['ai', 'psd', 'eps'];
    const filteredFiles = files.filter(file => {
      const extension = file.name.split('.').pop().toLowerCase();
      return allowedExtensions.includes(extension);
    });

    // setSelectedGraphicFiles(prevFiles => [...prevFiles, ...filteredFiles]);

    // const invalidFiles = files.filter(file => !allowedExtensions.includes(file.name.split('.').pop().toLowerCase()));

    // if (invalidFiles.length > 0) {
    //     setAlertMessageAi(`Invalid file(s) detected: ${invalidFiles.map(file => file.name).join(', ')}. Only PNG, JPG, and JPEG files are allowed.`);
    // } else {
    //     setAlertMessageAi('');
    // }
    if (selectedGraphicFiles.length < 1) {
      setSelectedGraphicFiles(prevFiles => [...prevFiles, ...filteredFiles]);

      const invalidFiles = files.filter(file => !allowedExtensions.includes(file.name.split('.').pop().toLowerCase()));

      if (invalidFiles.length > 0) {
        setAlertMessageAi(`Invalid file(s) detected: ${invalidFiles.map(file => file.name).join(', ')}. Only ai, psd and eps files are allowed.`);
      } else {
        setAlertMessageAi('');
      }
    }

    else {
      setAlertMessageAi('You can only select one file at a time. Please remove the existing file before selecting a new one.');
    }
  };

  const handleSubmitFile = () => {
    setLoadingButton(true)
    console.log(handleLinkFile);
    if (selectedImageFiles.length < 1 && selectedGraphicFiles.length < 1) {
      setLoadingButton(false)
      return true
    }

    const data = new FormData();
    const graphicData = new FormData();

    data.append("integerId", props.storeOverview.integerID);
    data.append("assetCategory",props.assetCategory);
    data.append("subCategoryId", props.subCategoryId? props.subCategoryId:'');
    data.append("assetIdentifier", props.assetIdentifier? props.assetIdentifier:'');
    data.append("notes", '');

    if (selectedImageFiles.length > 0) {
      // data.append("fileType", 'Image');
      for (let i = 0; i < selectedImageFiles.length; i++) {
        data.append("attachmentFiles", selectedImageFiles[i]);
      }
      if (selectedGraphicFiles.length < 1) {
        data.append("attachmentFiles1", 0);
      }
      if (handleLinkFile != false) {
        data.append("handleLinkFile", handleLinkFile);
      }
      else {
        data.append("handleLinkFile", 0);
      }
    }

    if (selectedGraphicFiles.length > 0) {
      for (let i = 0; i < selectedGraphicFiles.length; i++) {
        data.append("attachmentFiles1", selectedGraphicFiles[i]);
      }
      if (selectedImageFiles.length < 1) {
        data.append("attachmentFiles", 0);
        data.append("handleLinkFile", 0);
      }
    }

    uploadAssetsService(props.token, data).then((res) => {
      if (res) {
        console.log(res)
        setSelectedImageFiles([])
        setSelectedGraphicFiles([])
        setHandleLinkFile(false)
        props.setFileListOfAssets(res.fileList)
        props.handleBackFromUploadAssetsImages()
        setLoadingButton(false)

      }
    })
  };
const columns = [
        {
            Header:'Id',
            accessor:'id',
            Cell: (cellInfo) => (
              <div className="d-flex justify-content-center w-100 ">
                <p className="m-0">{cellInfo.value}</p>
              </div>
            ),
          },
          {
            Header:'File Name',
            accessor:'actualFileName',
            Cell: (cellInfo) => (
              <div className="d-flex justify-content-center w-100 ">
                <p className="m-0">{cellInfo.value}</p>
              </div>
            ),
          },
          {
            Header:'Uploaded At',
            accessor:'createdAt',
            Cell: (cellInfo) => (
              <div className="d-flex justify-content-center w-100 ">
                <p className="m-0">{cellInfo.value}</p>
              </div>
            ),
          },
        
    ]

  return (
    <>
      <Card className='p-2'>
        <Row>
          <Col>
            <h6>
              <button onClick={() => props.handleBackFromUploadAssetsImages()} className=" back-btn mr-1 "  >
                <img src={BackButton} alt="not found" />
              </button>
              &nbsp;<b>{props.assetIdentifier} &nbsp; Assets</b>
            </h6>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <label className='ml-4'>
              <input
                type="checkbox"
                checked={isCheckedImg}
                onChange={(e) => setIsCheckedImg(e.target.checked)}
              />&nbsp;&nbsp;&nbsp;
              <b>Upload Images</b>
              <p className='ml-4'>Supported File (png,jpg,jpeg)</p>
            </label>

          </Col>
          <Col md={6} >
            <label className='ml-4'>
              <input
                type="checkbox"
                checked={isCheckedAi}
                onChange={(e) => setIsCheckedAi(e.target.checked)}
              />&nbsp;&nbsp;&nbsp;
              <b>Upload Editable Graphic File</b>
              <p className='ml-4'>Supported File (ai,psd,eps)</p>
            </label>

          </Col>
        </Row>
        <Row className=' justify-content-between'>
          {
            isCheckedImg ?
              <Col
                md={5}
                className="p-4 ml-4 dotted-box-upload-product"

              >
                <div
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                  onDragEnter={handleDragEnter}
                  onDragLeave={handleDragLeave}>


                  <label htmlFor="fileInput" className={`custom-brand-file-input text-center uploadfile ${dragging ? "dragging" : ""}`}>
                    <h5>
                      <FontAwesomeIcon icon={faCloudUploadAlt} style={{ fontSize: "30px" }} />
                    </h5>
                    <h5>Drag &amp; Drop Files </h5>
                    <h5>OR</h5>
                    <input type="file" id="fileInput" name="ticketAttachment" className="custom-brand-file" onChange={handleFileChange} style={{ display: "none" }} />
                    <Button onClick={() => document.getElementById("fileInput").click()} style={{ background: "#B2A1FF" }}>
                      Browse Files
                    </Button>
                  </label>
                  <p style={{ color: 'red' }}>{alertMessage}</p>
                  <p style={{ fontSize: "13px" }}>Attached File List</p>
                  <ul style={{ color: "green", fontSize: "13px" }}>
                    {selectedImageFiles.length > 0 ? (
                      selectedImageFiles.map((file, index) => (
                        <li key={index}>
                          {file.name}
                          <button className="btn" onClick={() => handleRemoveFile(file)}>
                            <FontAwesomeIcon color="red" size="15px" icon={faWindowClose} />
                          </button>
                        </li>
                      ))
                    ) : (
                      <li>No files attached</li>
                    )}
                  </ul>
                </div>
              </Col> : ''
          }
          {isCheckedAi ?
            <>
              {isCheckedImg ? '' : <Col md={6}>
              <ReactTable
                data={allOriginalFiles}
                columns={columns}
                className="-fixed -highlight -striped"
                getTheadProps={() => {
                  return {
                    style: {
                      overflowY: "hidden",
                      background: "#B1FA63",
                      color: "#243837",
                    },
                  };
                }}
                defaultPageSize={5}
                noDataText={"No Data Found."}
                />
              </Col>}

              <Col
                md={5}
                className="p-4 mr-4 dotted-box-upload-product"
              >
                {isCheckedImg ?
                  <><select className="form-control w-100 mb-3"
                    onChange={(e) => setHandleLinkFile(e.target.value)}>
                    <option value="">--Select Existing File--</option>
                    {
                      allOriginalFiles && allOriginalFiles.map((file) => (
                        <option value={file.id}>{file.actualFileName}</option>
                      ))
                    }
                  </select>
                  </> : ''}
                {!handleLinkFile && isCheckedImg ?
                  <h5 className='text-center'>OR</h5> : ''}
                {!handleLinkFile ?
                  <>

                    <div
                      onDrop={handleGADrop}
                      onDragOver={handleGADragOver}
                      onDragEnter={handleGADragEnter}
                      onDragLeave={handleGADragLeave}>
                      <label htmlFor="graphicFileInput" className={`custom-brand-file-input text-center uploadfile ${dragging ? "dragging" : ""}`}>
                        <h5>
                          <FontAwesomeIcon icon={faCloudUploadAlt} style={{ fontSize: "30px" }} />
                        </h5>
                        <h5>Drag &amp; Drop Files </h5>
                        <h5>OR</h5>
                        <input type="file" id="graphicFileInput" name="" className="custom-brand-file" onChange={handleGraphicFileChange} style={{ display: "none" }} />
                        <Button onClick={() => document.getElementById("graphicFileInput").click()} style={{ background: "#B2A1FF" }}>
                          Browse Files
                        </Button>
                      </label>
                      <p style={{ color: 'red' }}>{alertMessageAi}</p>
                      <p style={{ fontSize: "13px" }}>Attached File List</p>
                      <ul style={{ color: "green", fontSize: "13px" }}>
                        {selectedGraphicFiles.length > 0 ? (
                          selectedGraphicFiles.map((file, index) => (
                            <li key={index}>
                              {file.name}
                              <button className="btn" onClick={() => handleGraphicRemoveFile(file)}>
                                <FontAwesomeIcon color="red" size="15px" icon={faWindowClose} />
                              </button>
                            </li>
                          ))
                        ) : (
                          <li>No files attached</li>
                        )}
                      </ul>
                    </div>
                  </> : ''}


              </Col>
            </> : ''
          }

        </Row>
        <Row>
          <Col md={12}>
            <div className='d-flex justify-content-center m-3'>
              {isCheckedImg || isCheckedAi ?<Button className='btn-background-color-Proceed-gallery' 
               disabled={loadingButton && (selectedImageFiles.length < 1 && selectedGraphicFiles.length < 1)}
              onClick={() => handleSubmitFile()}>
                {loadingButton==true?'Submitting':'Submit'}</Button>:''}
            </div>
          </Col>
        </Row>
      </Card>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    token: state.loggedUser.token,
    accessLevel: state.loggedUser.accessLevel,
    status: state.loggedUser.status,
    error: state.loggedUser.error,
    newUser: state.loggedUser.newUser,
    needsAdvertisingAPIAuth: state.loggedUser.needsAdvertisingAPIAuth,
    user: state.LWA.user,
    isAuth: state.LWA.isAuth,
    enableMobileMenu: state.theme.enableMobileMenu,
    enableClosedSidebar: state.theme.enableClosedSidebar,
    storeOverview: state.accountOverview.store.storeOverview,
  };
};

export default connect(mapStateToProps)(UploadImagesAndOriginalFiles)